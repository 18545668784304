import RelicHD_Relic from '@/images/RelicHD_Relic.jpg'
import RelicHD_CAMPFIREENjiNE from '@/images/RelicHD_CAMPFIREENjiNE.png'
import RelicHD_Scalehack from '@/images/RelicHD_Scalehack.png'
import RelicHD_Rebootus from '@/images/RelicHD_Rebootus.png'
import RelicHD_Mealforward from '@/images/RelicHD_MF.png'
import RelicHD_3rd from '@/images/RelicHD_3rd.png'
import RelicHD_CFNW from '@/images/RelicHD_CFNW.jpg'
import RelicHD_Throttle from '@/images/RelicHD_Throttle.jpg'
import RelicHD_Fintenna from '@/images/RelicHD_Fintenna.png'
import RelicHD_Battery from '@/images/RelicHD_Battery.png'
import RelicHD_DIS from '@/images/RelicHD_DIS.png'
import RelicHD_GrouthStudio from '@/images/RelicHD_growth_studio.png'
import RelicHD_DUALii from '@/images/RelicHD_DUALii.png'
import RelicHD_RUFU from '@/images/RelicHD_RUFU.png'
import RelicHD_Pro3 from '@/images/RelicHD_Pro3.png'
import RelicHD_ALTILAN from '@/images/RelicHD_ALTILAN.png'

const relic = {
  serviceName: 'Relic',
  title:
    '新規事業開発やイノベーション創出を支援する事業共創カンパニー「Relic」',
  description:
    'Relicでは大きく3つの事業で日本企業の新規事業開発やイノベーション創出を支援しています。新規事業支援に特化した独自のSaaSやプラットフォーム、新規事業開発やイノベーション創出に関わる一気通貫のトータルソリューション、そして共同事業開発/JVの立ち上げやベンチャー・スタートアップ投資を通じたオープンイノベーションが三位一体となり、世界でも類を見ない唯一無二の事業共創プラットフォームを形成しています。',
  image: { url: RelicHD_Relic },
  url: 'https://relic.co.jp/'
}
const campfire = {
  serviceName: 'CampfireEnjine',
  title:
    '企業のクラウドファンディング事業を支援し、新たな挑戦が生まれる仕組みを実装する「株式会社CAMPFIRE ENjiNE」',
  description:
    'Relicホールディングスは、国内最大のクラウドファンディング「CAMPFIRE（キャンプファイヤー）」を運営する株式会社CAMPFIREと、合弁会社「株式会社CAMPFIRE ENjiNE」を2022年1月に設立しました。RelicがENjiNEの提供を通じてこれまで培ってきた企業向けのクラウドファンディングサイト運営における知見・サービスと、CAMPFIREが持つクラウドファンディングのプロジェクトオーナーや支援者に関する知見・アセットを組み合わせてENjiNEをさらに進化させ、これまで以上に企業がクラウドファンディング事業に挑戦しやすく、その挑戦が成功する為の仕組みを提供いたします。',
  image: { url: RelicHD_CAMPFIREENjiNE },
  url: '/group/campfire-enjine'
}
const scalehack = {
  serviceName: 'Scalehack',
  title: '大義ある事業のスケールアップを総合支援する「株式会社Scalehack」',
  description:
    '「人と事業の成長を"ハック"するエコシステムを生み出す。」ことをビジョンに、事業のスケールアップを総合支援するプラットフォーム（Scalehackシリーズ）の展開により、事業の急拡大や持続的成長を実現する会社です。「4,000社を超える支援実績と成果が実証されたメソッド」、「独自のネットワークで構築した国内最大級のスケールハック人材DB」、「事業理解度の高いプロフェッショナルによるプロジェクトマネジメント」を強みに、セールス総合支援プラットフォーム「Scalehack for Sales」、マーケティング総合支援プラットフォーム「Scalehack for Marketing」、オペレーション総合支援プラットフォーム「Scalehack for Operation」、エンジニアリング総合支援プラットフォーム「Scalehack for Engineering」の4つの柱となる事業を総合的に展開しております。',
  image: { url: RelicHD_Scalehack },
  url: 'https://scalehack.co.jp/'
}
const rebootus = {
  serviceName: 'Scalehack',
  title:
    '事業再生・再成長や事業のM&A・売買や再配置に関する総合支援事業 | Reboo+',
  description:
    '「事業の再起動＋αの循環で、再挑戦を民主化する。」をミッションに、事業やプロダクト・サービスの現状やポテンシャルを適切な分析/評価により見極めた上で、事業再生・再成長を総合支援する「リノベーションスタジオ事業」もしくは事業のM&A・売買や再配置を総合支援する「リロケーションスタジオ事業」を提供します。これによりあらゆる事業を再起動し、＋αの価値や意義を乗せたリノベーション・リロケーションにより「再挑戦の民主化」を実現することで、志のある誰もがより挑戦しやすい社会を目指します。',
  image: { url: RelicHD_Rebootus },
  url: '/group/rebootus'
}
const mealforward = {
  serviceName: 'MEAL FORWARD',
  title: '食の流通と購買のイノベーションを実現する「株式会社MEAL FORWARD」',
  description:
    '「日本の食品事業を前進させ、食の未来を創り出す」 「食を通じて、人々の健康・豊かさ・幸せを前進させる」をコンセプトに、フードテック/フードイノベーション事業を通じて食の変革を目指します。展開事業として、消費者のデイリーな食品自家需要に応えるECサイト及びアプリのローンチを行い、地域・地場産業・生産者と連携、特長と強みのある食品のブランド化やプロデュースを通じた地域創生事業を行います。これら事業をMEAL as a Service（通称MaaS）として、食の流通と購買のフードイノベーションを実現させ、食の未来を創り出していきます。',
  image: { url: RelicHD_Mealforward },
  url: '/group/mealforward'
}
const thirdeconomy = {
  serviceName: '3rd Economy',
  title: 'WEB3と経済の融合を。世界を変える3つ目の経済圏を。',
  description:
    '3rd Economyは、従来一部の法人間の取引に留まっていた「金銭債権の売買」のマーケットを個人にも解放し、トークン化債権のマーケットプレイスとしての提供を行います。ブロックチェーンのテクノロジー等を活用し、本格的な資産運用でありながらも、楽しみながら無理なくトライするできる仕組みの構築を行い、より多くの方が将来に向けた資産運用、資産形成にチャレンジできる世界を生み出すことを目指します。',
  image: { url: RelicHD_3rd },
  url: '/group/3rdeconomy'
}
const newCFNW = {
  serviceName: 'CFNW',
  title:
    'SaaS型クラウドファンディングプラットフォーム「CROWDFUNDING NETWORK Powered by ENjiNE」',
  description:
    '初期費用無料で保守・運用費用などのコストを抑えながらスピーディに独自のサイトを立ち上げることができるSaaS型クラウドファンディングプラットフォームとして、国内シェアNo.1の導入実績があります。導入サイト/メディア間でプロジェクトを同時掲載して相互集客ができるネットワーク機能を保有しており、大手メディア等と連携することが可能です。また、クラウドファンディングにおける「購入型」「寄付型」「ふるさと納税型」「不動産投資型」「融資型」「越境型」などのあらゆる類型に対応しており、顧客の共感をベースに共創するファンマーケティングや、新製品/新サービスの事業コンセプトや顧客の受容性を検証するテストマーケティング、または新たな資金調達の手法やキャッシュフローの改善など、これまでにないマーケティングやファイナンスの形を実現します。',
  image: { url: RelicHD_CFNW },
  url: 'https://relic.co.jp/services/enjine/'
}
const throttle = {
  serviceName: 'Throttle',
  title: 'イノベーションマネジメント・プラットフォーム「Throttle」',
  description:
    '大企業〜スタートアップまで、1,500社以上に導入される国内シェアNo.1のイノベーションマネジメント・プラットフォームです。すべての企業や組織におけるイノベーションマネジメントの実現を支援するSaaS型プラットフォームを開発・運営しています。これにより、新規事業創出プログラムや社内ベンチャー制度、アクセラレーションプログラム等におけるアイデアやプランの創出や共有、案件管理・評価〜育成・メンタリングや、社内外の起業家/イノベーション人材などの発掘やチームビルディング等を通じたPoC〜事業化までをワンストップで効率的に支援します。また、継続的に運用することでタレントマネジメントや人材育成としても機能します。',
  image: { url: RelicHD_Throttle },
  url: 'https://relic.co.jp/services/throttle/'
}
const fintenna = {
  serviceName: 'Fintenna',
  title:
    'クラウドファンディング・ソーシャルレンディング専門メディア「Fintenna」',
  description:
    '金融型・不動産投資型など、クラウドファンディングやソーシャルレンディングを中心とした投資や資産運用などの金融に関する国内最大級のメディアとして、投資家や投資に興味・関心がある読者の皆様に良質な情報を発信。国内シェアNo.1のSaaS型クラウドファンディング構築サービス「ENjiNE」との事業シナジーを創出し、導入企業の成長支援を強化致します。',
  image: { url: RelicHD_Fintenna },
  url: 'https://relic.co.jp/services/throttle/'
}
const battery = {
  serviceName: 'Battery',
  title:
    '新規事業/イノベーション専門メディア「Battery」＋オウンドメディア・プラットフォーム',
  description:
    '企業内イノベーター、起業家、スタートアップ、マーケティングなど、新規事業やイノベーション共創の原動力となる情報やニュース、成功/失敗事例やコラムなどを紹介するオウンドメディア「Battery」を運営。 また、Batteryに採用しているオウンドメディアの構築/運営を支援するCMS/プラットフォームを大手/優良メディアに提供し、運営している実績が多数あります。現状のサイト分析から最適化するためのSEO対策やサイト改善など、メディアとしてのグロースだけでなく最終的な事業拡大やマネタイズも見据えた多角的な支援を実現します。',
  image: { url: RelicHD_Battery },
  url: 'https://relic.co.jp/battery/'
}
const dis = {
  serviceName: 'DIS',
  title: '事業共創型エンジニアリングサービス | Digital Innovation Studio',
  description:
    '1000社を超える大手企業やベンチャー/スタートアップの新規事業に携わってきたRelicがこれまでに培ったテクノロジーやデザインを駆使し、新規事業のアイデア検証から事業化までを一気通貫で支援する共創型エンジニアリングサービスです。テクノロジー人材やクリエイティブ人材だけでなく、事業開発のプロフェッショナル人材が多く在籍するRelicが「新規事業×デジタル領域」において頻出する課題に対し最適な解決アプローチを提供することで日本企業のデジタル・イノベーションを加速し、成功確度を高めます。',
  image: { url: RelicHD_DIS },
  url: 'http://relic.co.jp/services/dis/'
}
const growthStudio = {
  serviceName: 'GrowthStudio',
  title:
    '新規事業の「1→10」からマーケティング/営業組織のDXを加速するプラットフォーム | Growth Studio',
  description:
    '4,000社を超える大手企業やベンチャー/スタートアップの新規事業に携わってきたRelicが新規事業開発の知見やテクノロジーを駆使し、新規事業の事業検証から成長・拡大、マーケティング/営業組織のDXを加速するプラットフォームです。事業性の検証から成長・拡大（1→10/10→100）において必要な「ナレッジ」 「テクノロジー」「データベース」「ヒューマンリソース」 「グロースデザイン」の提供を通じて、KGI/KPIを達成するためのグロース戦略や具体的な戦術・施策のプランニング、実行/運用～体制構築までを一気通貫してサポートし、新規事業の成功確度を高めていきます。',
  image: { url: RelicHD_GrouthStudio },
  url: 'http://relic.co.jp/services/blitz/'
}
const dualii = {
  serviceName: 'DUALii',
  title: 'インキュベーションパートナー・プラットフォーム「DUALii」',
  description:
    '独自の事業開発スキームにより、不確実性の高い検証フェーズではReLicが運営主体としてスピーディーに事業開発/検証を進め、本格事業化のタイミングでは大企業が主導する最適な組織体制での事業化を実現します。これにより、大企業の新規事業開発におけるリスクを低減しつつ、大企業の構想やアセットとベンチャー・スタートアップ企業の機動力・実行力を掛け合わせた、これまでに無い画期的な事業創出を実現します。',
  image: { url: RelicHD_DUALii },
  url: 'http://relic.co.jp/services/dualii/'
}
const RUFU = {
  serviceName: 'RUFU',
  title: 'プロダクトの伝播を促進する事業を展開する「RUFU株式会社」',
  description:
    '「プロダクトへの顧客の熱狂を可視化し、伝播させる」をミッションに、SaaS for SaaSの領域で、プロダクトの伝播を促進するための事業を展開します。',
  image: { url: RelicHD_RUFU },
  url: 'https://corp.rufu.io/'
}
const pro3lab = {
  serviceName: 'Pro3Lab',
  title:
    '『エンジニアリングによるテコの力』で、世の中を変革する『大義あるプロダクト』を創る、株式会社Pro3Lab',
  description:
    '株式会社Pro3Labでは、開発初期投資の壁に着目し、エンジニアが開発工数を先に投資し、将来の売上や収益から投資コストを回収することで、初期開発費用を抑え、新たな開発に挑戦しやすくする「完全成果報酬型エンジニアリング」モデルを提供することで、大志あるパートナー様と共にwebアプリなどのソフトウェアを共同開発することを実現します。',
  image: { url: RelicHD_Pro3 },
  url: 'https://pro3-lab.co.jp/'
}
const altilan = {
  serviceName: 'ALTILAN',
  title:
    '月・火星以遠を目指す宇宙機開発と、そのプロセス・成果を用いた関連事業の展開. および産官学連携による非宇宙/異業種企業の参入促進',
  description:
    'ALTILANは、誰もが行きたい星に行ける世界・時代の実現を目指し、月や火星の先に到達する宇宙機の開発および事業化を、新規事業開発の豊富な経験・ノウハウとシステムズエンジニアリングの知見を用いてリードします。遠い星を目指す活動の中で生まれる成果の1つ1つを事業機会として捉え、さまざまな領域・テーマで事業を生み出して参ります。また、弊社の活動にご参画いただける皆さまに対して、新しい事業の創出、宇宙ビジネス領域への参入支援という形で貢献します。',
  image: { url: RelicHD_ALTILAN },
  url: '/group/altilan'
}

export const serviceList = [
  relic,
  campfire,
  scalehack,
  rebootus,
  mealforward,
  thirdeconomy,
  newCFNW,
  throttle,
  fintenna,
  battery,
  dis,
  growthStudio,
  dualii,
  RUFU,
  pro3lab,
  altilan
]
